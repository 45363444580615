const INITIAL_STATE = {
	name: '',
}

const changeAuthState = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "ON_LOGIN_SUCCESS": {
			return {
				...state, 
				name: action.payload 
			};
		}
		default:
			return state;
	}
}

export default changeAuthState;
