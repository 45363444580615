import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './scss/style.scss';

import PublicRoute from './hoc/PublicRoute';
import PrivateRoute from './hoc/PrivateRoute';
// import ResetPassword from './views/pages/forgotPassword/ResetPassword';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/forgotPassword/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));


class App extends Component {

  render() {
    return (
      <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <PublicRoute restricted exact path="/login" name="Login Page" component={props => <Login {...props}/>} />
              <PublicRoute exact path="/forgot-password" name="Forgot Page" component={props => <ForgotPassword {...props}/>} />
              <PublicRoute exact path="/reset-password" name="Reset Page" component={props => <ResetPassword {...props}/>} />
              <PublicRoute restricted={false} exact path="/register" name="Register Page" component={props => <Register {...props}/>} />
              <PublicRoute exact path="/404" name="Page 404" component={props => <Page404 {...props}/>} />
              <PublicRoute exact path="/500" name="Page 500" component={props => <Page500 {...props}/>} />
              <PrivateRoute path="/apps/email" name="Email App" component={props => <TheEmailApp {...props}/>} />
              <PrivateRoute path="/" name="Home" component={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

export default App;
